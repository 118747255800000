.table_main{
   
    background-color: rgba(127, 255, 212, 0.438);
  
   
}
.table_main tr th{
    background-color: rgb(158, 185, 209);
    border: 1px solid black;
padding: 10px;
    
}
.table_main tr td{
    border: 1px solid black;   
    padding: 10px;
}
.table_container{
    width: 100%;
    display: flex;
    justify-content: center;

}