.gb_table {
    text-align: center;
    margin: auto;
    width: 80%;
    margin-top: 1cm;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.governing_sidehead {
    font-weight: 800;
    color: red;
    margin: auto;
    font-size: large;
    text-align: start;
}

.governing_content {
    width: 80%;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1cm;
    margin-top: 1cm;
    margin-bottom: 1cm;
}

.gb_table thead tr th {
    background: #000428;
   background: -webkit-linear-gradient(to right, #004e92, #000428);
    background: linear-gradient(to right, #004e92, #000428);
    

    color: white;
    font-size: large;
    font-weight: bold;
    padding: 8px;
}

.gb_table tbody tr:nth-child(even) {
    background-color: lightgray;
}

/* .gb_table tbody tr:nth-child(odd) {
    background-color: orangered; 
} */

.gb_table tbody tr td {
    padding: 7px;
    border: 2px solid white;
    background: #FFEFBA;
    background: -webkit-linear-gradient(to right, #FFFFFF, #FFEFBA);
     background: linear-gradient(to left, #FFFFFF, #FFEFBA);
    

}
.acdm_table{
    border: 2px solid  white;
    background:#FFEFBA ;
    background: -webkit-linear-gradient(to right, #FFFFFF, #FFEFBA);
    background: linear-gradient(to left, #FFFFFF, #FFEFBA); 
}