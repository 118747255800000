.library_about {
    width: 93%;
    margin: auto;
    display: flex;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 2cm;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 7cm;
    border-radius: 10px;
    cursor: pointer;
    transition: transform .5s;
    margin-bottom: 3cm;

}

.library_about:hover {
    transform: scale(1.02);
}

.library_about img {
    width: 40%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}

.library_about p {
    /* text-align: center; */
    margin: auto;
    font-size: 16px;
}

.library_about div {
    width: 50%;
    margin: auto;
}

.library_services {
    width: 50%;
    margin: auto;
}

.library_services_container {
    display: flex;
    margin-top: 40px;
    /* border: 1px solid black; */
    width: 93%;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    transition: transform .5s;
    cursor: pointer;

}

.library_services_container:hover {
    transform: scale(1.02);
}

.library_vision {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.library_vision div {
    border: 2px solid orangered;
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
}

.library_threebox_container {
    display: flex;
    gap: 1cm;

    margin-top: 1cm;
    justify-content: center;
}


.library_threebox {
    padding: 1cm;
    width: 45%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transition: transform .5s;
    cursor: pointer;

}

.library_threebox:hover {
    transform: scale(1.02);
}

.library_facilities_table {
    width: 98%;
    margin: auto;
}

.library_facilities_table tr {
    border: 1px solid aqua;
    text-align: center;
    padding: 10px;
}

.library_facilities_table tr td {
    border: 1px solid aqua;
    text-align: center;
    padding: 10px;
}

.library_facilities_table tr th {
    border: 1px solid aqua;
    text-align: center;
    padding: 10px;
    background-color: orangered;
    color: white;
}

.library_accordion {
    background: #FC5C7D;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #6A82FB, #FC5C7D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: none;
    /* margin-top: 10px; */

}

.library_staff_table {
    width: 50%;
}

.library_threebox p {
    font-size: 20px;
    /* margin-top: 20px; */
}

.library_staff {
    width: 93%;
    margin: auto;
    display: flex;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 7cm;
    border-radius: 10px;
    cursor: pointer;
    transition: transform .5s;
}

.library_staff:hover {
    /* scale: ; */
    transform: scale(1.02);
}

.library_staff img {
    width: 40%;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
}

.library_staff_table {
    width: 50%;
    margin: auto;
}

.library_staff_table tr {
    border: 1px solid aqua;
    text-align: center;
    padding: 10px;
}

.library_staff_table tr td {
    border: 1px solid aqua;
    text-align: center;
    padding: 10px;
}

.library_staff_table tr th {
    border: 1px solid aqua;
    text-align: center;
    padding: 10px;
    background-color: orangered;
    color: white;
}

.staff_title {
    text-align: center;
    margin-top: 20px;
}

@media only screen and (max-width: 780px) {
    .library_about {
        width: 98%;
    }

}

.container_text p {
    text-align: start;
    font-size: 13px;

}