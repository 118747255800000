
.container_bg{
    text-align: center;
     align-items: center;
 }
 .text{
     text-align: center;
     /* margin-left: 30px; */
     font-family: Georgia, 'Times New Roman', Times, serif;
     margin: auto;
     font-weight: bold;
 }
 
 .btn_text{
     
     margin: 30px;
     display: flex;
     flex-direction: row;
     gap: 50px;
     width: 100%;
     height: 20%;
     padding: 5px 8px;
     border-width: 5px;
     margin-right: 30px;
     align-items: center;   
 }
  
 .btn_audit {
     font-size: 18px;
     line-height: 1;
     padding: 20px 0;
     width: 100%;
     text-align: center;
     text-align: max-connect;
     font-size: 18px;
     font-weight: 100;
     text-transform: uppercase;
     letter-spacing: 0.5px;
     border-radius: 30px;
     padding: 15px 0px 15px 0px;
     background-color: rgb(0,51,51);
     color: white;
   
 }
 
 .btn_audit:hover{
 background-color:rgb(0,153,153);
 color: black;
 }
 .btn_sem{
    font-size: 18px;
    line-height: 1;
    padding: 20px 0;
    width: 50%;
    text-align: center;
    font-size: 18px;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 30px;
    padding: 15px 0px 15px 0px;
    background-color: rgb(151, 231, 72);
    gap: 100px;
 }
 .funct{
    font-size: 15px;
    font-weight: bold;
 }
 .Course_pdf{
 border: 0.1px solid black;
 width: max-content;
 display: flex;
 flex-direction: column;
 margin: auto;
 text-align: center;
 border-radius: 20px;
 padding: 20px;
 box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
 /* background-color: black; */
 font-weight: bold;

 }
@media screen and (max-width: 768px) {
    .btn_text {
        flex-direction: column;
        gap: 15px;
    }

    .btn_audit {
        width: 100%; 
        padding: 12px 0; 
        font-size: 16px;
    }

    .btn_sem {
        width: 100%; 
        padding: 12px 0;
        font-size: 16px;
    }

    .Course_pdf {
        width: 100%; 
        padding: 15px;
    }

   
    .text {
        font-size: 16px; 
    }

    .funct {
        font-size: 14px; 
    }
}


@media screen and (max-width: 480px) {
    .btn_audit {
        width: 90%; 
        font-size: 14px;
        padding: 10px 0;
    }

    .btn_sem {
        width: 90%;
        font-size: 14px;
        padding: 10px 0;
    }

    .text {
        font-size: 14px; 
    }
}
