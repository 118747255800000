.placement_records_bg_cnt{
    background-color: #E4E8EA;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content:flex-start;
    gap: 10px;
    padding: 35px;
    border-radius: 8px;
}

.side_cnt_placements{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 12%;
}
.side_cnt_placements button{
    background-color: rgb(250, 207, 150);
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
}
.main_cnt_placements{
    flex-grow: 1;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    padding: 15px;
}

.active_btn{
    background-color: #fff !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.recruiters_table_cnt{
    width: 100%;
    background-color: #fff;
}

.recruiters_table{
    width: 80%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    padding: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.recruiters_table tr{
    border-bottom: 1px solid lightgray;
}
.recruiters_table tr th{
    padding: 10px;
    background-color: lightgray;
}
.recruiters_table tr td{
    padding: 10px;
    border: 1px solid lightgray;
}
