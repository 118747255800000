.about_img {
    border-radius: 20px;
    width: 18cm;
    border: 2px solid gray;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 10cm;
    /* margin-bottom: 10px; */
    margin-left: 10px;
    /* margin-right: 2%; */
    margin-top: 1cm;

}
.aboutmain
{
    display: flex;
    flex: start;
    gap: 0.5cm;
    

}

.about_img_cntr {
    text-align: center;
    display: flex;
    margin-left: 20px;
    margin-top: 5cm;
    align-items: center;

}

.about_head {
    /* margin-left: 7%; */
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
    font-style: oblique;
    font-weight: bold;
    text-decoration: underline;
    font-size: 30px;
}

.about_main {
    border: 2px solid black;
    width: 95%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 50px;
    margin-top: 20px;
    padding: 20px;
   
    /* box-shadow:inset rgba(99, 99, 99, 0.628) 0px 0px 8px 10px; */
}

.cnt_head {
    text-align: center;
    font-weight: bold;
}
.main-div{
    font-size: 14px;
    text-align: justify;
}

/* 
.cnt_head {
    text-align: center;
    margin: auto;
    text-align: center;
    font-size: 40px;
    font-weight: bold; */
/* margin-top: 1cm; */
/* margin-bottom: 20px; */

/* } */

.about_contant {
    text-align: center;
    width: 97%;
    margin: auto;
    margin-top: 40px;
    border: 2px solid gray;
    margin-bottom: 30px;
    border-radius: 20px;
    padding: 2%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
    font-family: Arial, Helvetica, sans-serif;
    height: 6cm;

    /* font-weight:bold; */
    /* font-size: 120px; */

}

.prg_content {
    font-size: 15px;
    margin-top: -8px;
}

.about_contant:hover,
.about_img:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 15px 5px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .about_img {
        width: 100%;
        height: 170px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .about_img_cntr {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: auto;
        margin-bottom: -30px;
    }

    .about_contant {
        width: 95%;
        margin-bottom: 10px;
        font-size: 10px;
    }
    .aboutmain {
        display: flex;
        flex-direction: column;
    }
}

.about_up {
    display: flex;
    justify-content: space-between;
}

.about_txt_cnt {
    width: 60%;
    margin: auto;
}

.about_bottom {
    /* margin-top: 5px; */
}

.about_bottom p,
.about_txt_cnt p {
    /* margin-top: -5px; */
    font-size: 14px;
    text-align: justify;
}