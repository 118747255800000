*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    /* text-transform: capitalize; */
    text-decoration: none;
}

body{
    min-height: 100vh;
    /* background: #FFA17F;  
    background: -webkit-linear-gradient(to right, #00223E, #FFA17F);  
    background: linear-gradient(to right, #00223E, #FFA17F);  */
}
ul{
  margin: 0 !important;
  padding: 0 !important;
}