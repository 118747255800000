body {
  width: 100vw;
  overflow-x: hidden;
}

.App {
  width: 100vw;
}

.hm_header {
  background-color: #65212a;
}

.hm_header_cnt {
  width: 100%;
  height: 200px;
  background-image: url("../../assets/mittapalliheader.png");
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.hm_header_logo_img {
  position: absolute;
  top: 10%;
  left: 23%;
  height: 150px;
  width: 150px;
}

.menubar {
  height: 50px;
  background-color: #fff;
}

.menu_inner {
  margin: auto;
}

.img_upper_cntr {
  background: rgba(24, 29, 56, 0.7);
}

.card_cntr1 {
  display: flex;
  margin: auto;
  width: 95%;


}

.card_cntr2 {
  display: flex;
}

.card_cntr1 .boxes {
  text-align: center;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  height: 400px;
}

.card_cntr1 .boxes1 {
  text-align: center;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  height: 330px;
}

.card_cntr2 .boxes {
  text-align: center;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  height: 400px;
}

.card_cntr2 .boxes1 {
  text-align: center;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  height: 440px;
  width: 380px;
  justify-content: center;
}

.course_offerd {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.mou_img {
  /* max-height: 100px; */
  height: 2cm;
  /* width: 3cm; */
  /* object-fit: contain; */
  border-radius: 10px;
}

.mou_img_cnt {
  background-color: white;
  /* padding: 10px; */
  border-radius: 10px;
  height: 2cm;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 1cm;
}

.mou_cnt_bg {
  background: #000046;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #1CB5E0, #000046);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #1CB5E0, #000046);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 25px;
}

.heading {
  color: white;
}

.img_cnt_experts {
  height: 120px;
  overflow: hidden;
}

.img_experts {
  height: auto;
  width: 100%;
}

.carousal_img {
  /* width: 100%;
  max-height: 650px; */
  border-radius: 10px;
  height: 500px;
  width: 100%;
  object-fit: fill;
}

.carousal_bg_cnt {
  text-align: center;
  margin: 20px;
  margin-top: 9px;
  padding: 30px;
  border: 1px solid rgb(195, 193, 193);
  border-radius: 10px;
  height: fit-content;
  background-color: #f2f2f2;
}

.notification_prg {
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: large; */
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 15px;
  /* color: rgb(36, 4, 23); */
  color: whute;
}

.notification_banner_cnt {
  /* background: #b79891; fallback for old browsers */
  /* background: -webkit-linear-gradient(
    to right,
    #94716b,
    #b79891
  ); Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(
    to right,
    #94716b,
    #b79891
  ); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

.scale-up-bl {
  animation: scale-up-bl 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-bl {
  0% {
    transform: scale(0.5);
    transform-origin: 0 100%;
  }

  100% {
    transform: scale(1);
    transform-origin: 0 100%;
  }
}

.fade-in-up {
  animation: fadeInUp 0.5s ease-out;
  /* Adjust duration and timing function as needed */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.swing-out-top-bck {
  animation: swing-out-top-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
}

@keyframes swing-out-top-bck {
  0% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }

  100% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
}

@media (max-width: 991.98px) {
  .carousal_bg_cnt {
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .carousal_img {
    border-radius: 0;
  }
}

/* .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link.active {
  color:red;
} */

.acc_img {
  border-radius: 5px;
  height: 90px;
  width: 90px;
}

.logo_img1 {
  position: absolute;
  top: 20%;
  right: 5%;
}

.logo_img3 {
  position: absolute;
  top: 20%;
  right: 12%;
}

.logo_img2 {
  position: absolute;
  top: 20%;
  right: 20%;
}

.img_cnt {
  width: 100%;
  height: 300px;
  padding-right: 10px;
  display: flex;
  /* justify-content: flex-end; */

}

.container_img {
  border-radius: 9px;
}

/* .img_cnt img{
  object-fit: contain;
} */
.prg_content {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  
}

.btn_cnt {
  border: 10px;
  border-radius: 5px;
  color: rgb(32, 29, 241);
  background-color: rgb(231, 195, 234);
}

/* responsive */
@media (max-width: 780px) {
  .card_cntr2 {
    display: flex;
    flex-direction: column;
  }
}

.container-xxl {
  margin-top: -55px;
}

/* .notification_scroll {
  animation: marquee-animation 5s linear infinite;
  /* Adjust animation duration as needed */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
  color: white;
  height: 5cm;
  margin: auto;

} */ 

.notification_scroll {
  background: #0f0c29;
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
  height: 5cm;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  
  
}
.notification_scroll a {
  color: #fff;
  
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.notification_scroll a:hover {
  text-decoration: underline;
  text-shadow: 2px 2px 4px yellow;
  
}

@keyframes marquee-animation {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-200%);
  }
}
.pop{
  height: 12cm;
  width: 100%;
}