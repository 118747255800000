.library_accordion_AC {
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: none;
    width: 50%;
    margin: auto;
}

.academic_calender {
    width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.calender_btn_container {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.calender_btn_container button {
    width: 90%;
    margin: auto;
    height: 1cm;
    background-color: rgb(116,203,197);
    font-weight: bold;
    color: white;
    border-radius: 5px;
    border: 5px 2px 0px 0px solid black;
}

.calender_btn_container button:hover {
    background-color: white;
    color: black;
}

.calender_btn_container a {
    width: 30%;
    margin: auto;
    height: 1cm;
    /* background-color: blue; */
    font-weight: bold;
    color: white;
    border-radius: 5px;
    /* border: 2px double black; */
}

/* syllabus */

.syllabus_accordion  {
    width: 50%;
    margin: auto;
    margin-top: 1cm;
}
.syllabus_container {
    width: 80%;
    margin: auto;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
}
.syllabus_table_container {
    width: 90%;
    margin: auto;
    margin-bottom: 1cm;
}
.syllabus_table thead {
    width: 100%;
    background-color: orangered;
    color: white;
    height: 1cm;
}
.syllabus_table thead tr th {
    color: white;
    padding: 10px;
}
.syllabus_table {
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.syllabus_table tbody {
    padding: 10px;
}
.syllabus_table tbody tr td {
    padding: 10px;
}
.syllabus_btn {
    background-color: darkblue;
    color: white;
    margin: auto;
    border: none;
    margin-right: 10px;
    padding: 5px;
    width: 2cm;
    border-radius: 5px;
    background-color: rgb(218, 155, 212);
    color: crimson;
    display: flex;
    margin-left: 400px;
}
.syllabus_btn:hover {
    background-color: orangered;
}
.syllabus_table tbody tr:hover {
    background-color: rgba(255, 68, 0, 0.518);
    /* color: white; */
    cursor: pointer;
    .syllabus_btn{
        background-color: black;
    }
    .syllabus_btn:hover {
        background-color: blue;
    }
}
@media screen and (max-width: 600px) {
    .library_accordion_AC {
      width: 100%;
      max-width: 90%; 
      background-color: rgb(116,203,197);
      border-radius: 10px;
    
    }
    .calender_btn_container {
      flex-wrap: wrap; 
      gap: 10px; 
      padding: 10px 0;  
       }
  }
  