.SmceStudentGrivanceCell {
    padding: 29px;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid black; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: auto;
    margin-bottom: 1cm;
    border-radius: 10px;
    /* max-height: 12cm; */

}
.thick-check-mark {
  width: 19px;
  height: 30px;
  border: 6px solid #333;
  border-left: 0;
  border-top: 0;

  transform: rotate(45deg);
}
.gr_body_cntr {
    width: 70%;
    margin: auto;
    /* border: 2px solid black; */
    padding: 10px;
    margin-top: 0;
    overflow-y: auto;
    height: 11cm;
}
.gr_btn_cntr {
    display: flex;
    flex-direction: column;
    width: 28%;
    gap: 15px;
    border: 3px solid black;
    padding: 15px;
    border-radius: 10px;
    margin: auto;
    top: 0;
    
}
/* .gr_header {
  text-align: center;
  padding: 5px;
  font-size: 15px;
  background-color: black;
  color: white;
  border-radius: 10px;
  border: 2px solid black;
  font-weight: bold;
} */


.gr_btn_cntr button {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.gr_btn_cntr button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.gr_btn_cntr button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}



.gr_header {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.gr_header:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.gr_header:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
}