.streangth_left_cnt {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/streangths.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.streangth_left_cnt p {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  padding: 20px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 100 !important;
  
}
