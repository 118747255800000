.grevience_form_main_container {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: center;
}

.grevience_form_heading {
  color: orange;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 5px solid orangered;
  /* width: fit-content; */
  text-align: center;
  margin: 20px;
  padding-bottom: 10px;
}

.grevience_form_sub_container {
  background-image: linear-gradient(to right, #dbd4b4, #e1f5c4);
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  margin: 20px;
}

.grevience_form_container input {
  max-width: 250px;
  width: 80%;
  border: 2px solid rgb(43, 169, 169);
  padding: 8px;
  border-radius: 12px;
  background-color: #f4f7f9;
  flex-grow: 1;
  min-height: 40px;
}

.grevience_form_container input:focus {
  outline: 2px solid orange;
  border: none; /* Specify the outline style and color */
}

.grevience_form_container textarea {
  background-color: #f4f7f9;
  border-radius: 10px;
  border-radius: 12px;
  border: 2px solid rgb(43, 169, 169);
  width: 90%;
}

.grevience_form_container label {
  color: #02334d;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  flex-grow: 1;
  margin-bottom: 8px;
}

/* Greavience form */

.grevience_form_header {
  text-align: center;
  background-color: orangered;
  color: white;
  height: 1.7cm;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}
.grevience_form_main {
  width: 50%;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 1cm;
}
.grevience_form_body {
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
  background: #d3cce3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e9e4f0,
    #d3cce3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #e9e4f0,
    #d3cce3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.grevience_inp_container {
  margin: auto;
  text-align: start;
}
.grevience_inp_container input,
.grevience_inp_container select {
  width: 100%;
  margin-bottom: 10px;
  height: 1cm;
  /* text-align: center; */
  border-radius: 10px;
  border: 1px solid black;
  outline: none;
  padding-left: 25px;
}
.grevience_inp_container textarea {
  width: 100%;
  margin-bottom: 10px;
  /* text-align: center; */
  height: 2cm;
  border-radius: 10px;
  padding: 10px;
}
.grevience_inp_container input:focus,
.grevience_inp_container select:focus,
.grevience_inp_container textarea:focus {
  border: 1px solid aqua;
}
.grevience_form_btn {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1cm;
  margin-top: 20px;
}
.grevience_form_btn button {
  width: 8cm;
  height: 1cm;
  background-color: orangered;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: none;
}
.grevience_form_btn button:hover {
  background-color: blue;
}

.submit_btn {
  background-color: #02334d;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  margin-bottom: 8px;
  color: #fff;
  border-radius: 5px;
  border-width: 0px;
  padding: 5px 10px;
}

.teacher_feedback_form_main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.teacher_feedback_form_container {
  background-color: #fff;
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 5px solid orange;
}

.teacher_feedback_form_container label {
  color: #02334d;
  font-weight: 700;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  flex-grow: 1;
  padding-bottom: 10px;
  padding-top: 20px;
}

.teacher_feedback_form_container span {
  color: #02334d;
  font-size: 15px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  flex-grow: 1;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.teacher_feedback_form_container input {
  border: 1px solid skyblue;
  border-radius: 5px;
  padding: 5px;
}

.teacher_feedback_form_container textarea {
  border: 1px solid skyblue;
  border-radius: 8px;
  padding: 5px;
}

.teacher_feedback_form_container input:focus {
  outline: 1px solid orange;
  border-width: 0px;
  border-radius: 5px;
  padding: 5px;
}

.teacher_feedback_form_container textarea:focus {
  outline: 1px solid orange;
  border-width: 0px;
  border-radius: 5px;
  padding: 5px;
}

.teacher_feedback_form_container select:focus {
  outline: 1px solid orange;
  border-width: 0px;
  border-radius: 5px;
  padding: 5px;
}

.teacher_feedback_form_container select {
  border: 1px solid skyblue;
  border-radius: 5px;
  padding: 5px;
  min-width: 200px;
}

.teacher_form_heading {
  color: #fff;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  text-align: center;
  border-top: "1px solid #02334d";
  border-bottom: "1px solid #02334d";
  padding: 10px;
}

.teacher_feedback_heading_container {
  background-image: linear-gradient(to right, #ed4264, #e6ad10);
  border-left: 5px solid #e9ac03;
  width: 100%;
  border-right: 5px solid #e55d87;
  border-radius: 10px;
}

input[type="radio"]:focus {
  outline: none;
}
