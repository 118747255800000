.Conatact-page-main{
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 45px;
}
.main-cont{
    background: #654ea3;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #eaafc8, #654ea3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #eaafc8, #654ea3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* color: white; */
    padding-top: 20px;
}
.Principal-details{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: white;
   
    border-radius: 20px;
    width:30%;
    height: auto;
    padding: 20px;
    margin: 20px;
}
.Princiapl_head{
    font-weight: bolder;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    padding-bottom: 20px;
}
    .Main-conatactus-details{
    display: flex;
    /* margin: auto; */
}
.Main_Training_placement_cell{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: white;
    width: 43%;
    height: auto;
    padding: 15px;
    margin: 5px;
    border-radius: 20px;
    margin-bottom: 10px;

   

}
/* .principal_name{
    font-weight: bold;
} */