.naac_head {
    text-align: center;
    margin-top: 10px;
}

.crt_main {
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
}

.naac_ssr_criterias {
    background: #d9a7c7;
    background: -webkit-linear-gradient(to right, #fffcdc, #d9a7c7);
    background: linear-gradient(to right, #fffcdc, #d9a7c7);
    margin-top: 20px;
    font-weight: bold;
}

.naac_ssr_inside {
    margin-bottom: 10px;
    padding-left: 20px;
}

@media only screen and (max-width: 780px) {
    .crt_main {
        width: 100%;
        margin: auto;
        margin-bottom: 50px;
    }
    .naac_ssr_criterias {
        width: 100%;
    }
    .naac_ssr_inside {
        margin-bottom: 10px;
        padding-left: 0px;
        font-size: 10px;
    }
}
