.notification_cnt{
    width: 100%;
    margin-bottom: 50px;
}
.notification_button_cnt{
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 100%;
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    

    padding: 20px;
}

.notification_button_cnt button{
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin: 5px;
    width: 400px;
    background-color: lightyellow;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    
}
.notification_pannel{
    display: flex;
    width: 100%;
    /* background-color: lightgray; */
    padding: 10px;
}

.notification_pannel_inner{
    /* background-color: lightblue; */
    width: 33%;
    padding: 20px;
}

.notification_content{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    /* padding: 10px; */
    margin: 5px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 15px;
    max-height: fit-content;
}
.notification_content .notification_date{
  background: #f12711;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f5af19, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
    
    color:#fbf6f6;
    padding: 10px 40px;
    height: 100%;
    width: 40%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    width: fit-content;
    white-space: nowrap;
}
.notification_content .notification_text{
    /* background-color: lightcoral; */
    padding: 10px;
    flex-grow: 1;
    
}


.marquee-wrapper {
    overflow: hidden;
    position: relative;
    height: 100%; /* Adjust height as needed */
  }
  
  .marquee-content {
    position: absolute;
    top: 100%;
    animation: marquee-animation 5s linear infinite;
  }
  
  @keyframes marquee-animation {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-200%);
    }
  }
  
  .marquee-content:hover {
    animation-play-state: paused;
  }
  @media (max-width: 780px){
    .notification_cnt{
      display: flex;
      flex-direction: column;
    }
    .notification_button_cnt{
      display: flex;
      flex-direction: column;
    }
    .notification_pannel{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .notification_pannel_inner{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  
