

.container_bg{
   text-align: center;
    align-items: center;
}
.text{
    text-align: center;
    margin-left: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
}

.btn_text{
    
    margin: 50px;
    display: flex;
    flex-direction: row;
    gap: 150px;
    width: 70%;
    height: 70%;
    padding: 5px 8px;
    border-width: 5px;
    margin-right: 30px;
    align-items: center;   
}
 
.btn_audit {
    font-size: 18px;
    line-height: 1;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 100px;
    padding: 25px 0px 25px 0px;
    background-color: rgb(151, 231, 72);
    color: white;
}

.btn_audit:hover{
background-color: red;

.btn_audit a {
    color: black;
}
}
.btn_audit a {
    color: white;
}

