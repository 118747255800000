.placementcell {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   padding: 80px;
    text-align: center;
}
.placement {
   text-align: left;
   margin: 0px;
}