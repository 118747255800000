.acadamic_calendar_bg_cnt {
  /* background-image: url('../../assets/acadamic_calendar.jpg');
    background-size: cover;
    background-position: center; */
  height: 100vh;
}
.page_header {
  background-color: #2f3a58;
}
.page_header h1 {
  text-align: center;
  padding: 15px;
  color: #eee2e6;
  text-transform: uppercase;
  /* word-spacing: 20px; */
  letter-spacing: 2px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px !important;
}
.year_select_cnt {
  padding: 30px;
  background-color: gray;
  width: fit-content;
  margin: auto;
  border-radius: 5px;
}
.year_select_cnt select {
  padding: 10px;
  width: 350px;
  border-radius: 5px;
  margin: 10px;
  outline: none;
  border: 1px solid lightgray;
}
.year_select_cnt label {
  color: #eee2e6;
  font-size: 20px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
}

.admission_header {
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  width: 100%;
  margin: auto;
  background-color: #003366;
  color: white;
  margin-top: 5px;
  padding: 10px;
}
.admission_main {
  width: 98%;
  margin: auto;
  padding: 50px;
}
.admission_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  background-color: transparent;
}

.admission_btn_container button {
  margin: auto;
  width: 8cm;
  height: 1cm;
  font-weight: bold;
  border-radius: 10px;
  background-color: blue;
  border: none;
  color: white;
}
.admission_btn_container button:hover {
  transform: scale(1.03);
  background-color: orangered;
  /* color: black; */
}
.admission_form_container {
  display: flex;
  flex-direction: column;
  list-style-type: upper-roman;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4),
    0px 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0px -3px 0px rgba(0, 0, 0, 0.2);

  width: 50%;
  padding: 50px;
  margin: 20px;
}

.admission_form_inp_container {
  display: flex;
  align-items: center;
  margin: 20px;
}
.admission_form_inp_container input {
  border: 1px solid rgb(43, 169, 169);
  padding: 8px;
  border-radius: 12px;
  background-color: #f4f7f9;
  min-height: 40px;
  width: 250px;
}

.admission_form_inp_container select {
  border: 1px solid rgb(43, 169, 169);
  padding: 8px;
  border-radius: 12px;
  background-color: #f4f7f9;
  min-height: 40px;
  width: 250px;
}

.admission_form_inp_container textarea {
  border: 1px solid rgb(43, 169, 169);
  padding: 8px;
  border-radius: 12px;
  background-color: #f4f7f9;
  min-height: 40px;
}

.admission_form_inp_container input:focus {
  outline: 2px solid orange;
  border: none; /* Specify the outline style and color */
}
.admission_form_inp_container label {
  color: #02334d;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  width: 250px;
}
.admission_label {
  margin: auto;
}
.admission_inp {
  margin: auto;
}


.admission_heading{
  text-align: center;
  color: #02334d;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  border-bottom: 5px solid orange;
  width: fit-content;
  font-size: 20px;
}

.span_ele{
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  color: #02334d;
  font-size: 16px;
  padding: 10px;
}