.iqac_header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.iqac_cuntant_container {
  text-align: left;
  width: 80%;
  margin: auto;
  /* border: 1px solid black; */
  border-radius: 10px;
  padding: 20px;
  padding-top: 40px;
  margin-top: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.iqac_sidehead {
  font-weight: 800;
  color: red;
  margin: auto;
  text-align: center;
  font-size: large;
}
.iqac_secondary {
  margin-top: 20px;
  font-weight: bold;
  color: rgb(88, 83, 83);
}
.iqac_links {
  /* text-align: center; */
  display: flex;
  margin-top: 20px;
}
.iqac_links button {
  /* margin: auto; */
  margin-left: 2.3cm;
  height: 1cm;
  width: 9cm;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 255, 0.596);
  border: none;
}
.iqac_links button:hover {
  background-color: black;
}

/* iqac membur */

.iqac_table_container {
  text-align: center;
}
.iqac_table {
  margin: auto;
  width: 70%;
  margin-top: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.iqac_table tr th {
  background-color: orangered;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
}
.iqac_table tr td {
  padding: 20px !important;
  border: 2px solid black;
}
.iqac_image {
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  width: 30%;
  margin-bottom: 30px;
}

/* AQAR */
.aqar_container {
  text-align: left;
  width: 60%;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.aqar_criterion {
  margin: auto;
  margin-top: 20px;
  color: gray;
  font-size: large;
}
.aqar_2ndhead {
  color: red;
  font-weight: bold;
  font-size: large;
}
.aqar_3rdline {
  margin-top: 20px;
  color: rgb(71, 82, 201);
  font-size: large;
}
.aqar_button_container {
  width: 80%;
  margin: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
}
.aqar_button_container button {
  margin: 10px;
  border: none;
  border-radius: 5px;
  width: 3cm;
  height: 1cm;
  background-color: #020f4f;
  color: white;
}
.aqar_button_container button:hover {
  background-color: rgba(0, 0, 255, 0.596);
}
