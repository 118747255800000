/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    text-decoration: none;
}

body{
    min-height: 100vh;
    background: #FFA17F; 
    background: -webkit-linear-gradient(to right, #00223E, #FFA17F);  
    background: linear-gradient(to right, #00223E, #FFA17F);
} */

.notification_banner_cnt {
  /* background-color: rgba(220, 133, 83, 0.872); */
  height: 55px;
  display: flex;
}

.search-input {
  /* color: white; */
  margin: auto;
  height: 1.2cm;
  border: 2px solid black;
  margin-top: 5px;
  margin-right: 30px;
  border-radius: 10px;
  text-align: center;
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

header {
  z-index: 1000;
  background-color: #020f4f;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  display: flex;
  align-items: center;
  height: 1cm;
}

header .logo {
  font-size: 25px;
  font-weight: bolder;
  color: #333;
}

header .navbar ul {
  list-style: none;
  z-index: 1000;
}

header .navbar ul li {
  position: relative;
  float: left;
}
.ntf_banner_cnt {
  background-color: #e2bd14;
  display: flex;
  height: 1.5cm;
}
.ntf_bg_cnt {
  display: flex;
  margin-left: 200px;
  color: rgb(24, 72, 216);
  gap: 5px;
  text-align: center;
  width: 100%;
}

header .navbar ul li a {
  font-size: 13px;
  padding: 5px 18px;
  color: #fff;
  display: block;
}

header .navbar ul li a:hover {
  background: #ffa17f;
  background: -webkit-linear-gradient(to right, #00223e, #ffa17f);
  background: linear-gradient(to right, #00223e, #ffa17f);
  color: #fff;
}

header .navbar ul li ul {
  position: absolute;
  width: 200px;
  background: #e2bd14;
  display: none;
  align-self: center;
  text-align: center;
  margin: auto;
}

header .navbar ul li ul li {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

header .navbar ul li ul li a {
  color: #000000;
}

header .navbar ul li ul li ul {
  left: 200px;
  top: 0;
}

header .navbar ul li:hover > ul {
  display: block;
}

#menu-bar {
  display: none;
}

header label {
  font-size: 20px;
  color: #333;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 991px) {
  header {
    padding: 20px;
  }

  header label {
    display: initial;
  }
  header .navbar ul li a {
    font-size: 13px;
    /* padding: 5px 18px; */
    color: black;
    font-weight: bolder;
    display: block;
  }

  header .navbar {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid rgba(167, 15, 15, 0.1);
    display: none;
    z-index: 1000;
  }

  header .navbar ul li {
    width: 100%;
  }

  header .navbar ul li ul {
    width: 100%;
    position: relative;
    left: 0;
    z-index: 1000;
  }

  header .navbar ul li ul li {
    background-color: #fff;
  }

  header .navbar ul li ul li ul {
    width: 100%;
    left: 0;
    z-index: 1000;
  }


  #menu-bar:checked ~ .navbar {
    display: block;
    background: #E2BD14;
    /* color: black; */
  }

  header .navbar ul {
    display: block;
    width: 100%;
    z-index: 1000;
  }

  header .navbar ul li {
    width: 100%;
  }

  header .navbar ul li ul {
    position: relative;
    width: 100%;
    z-index: 1000;
  }

  header .navbar ul li ul li {
    background-color: #eee;
  }

  header .navbar ul li ul li ul {
    width: 100%;
    left: 0;
  }

  header .navbar ul li ul li ul li {
    background-color: #ccc;
  }

  header .navbar ul li ul li ul li ul {
    width: 100%;
    left: 0;
    z-index: 1000;
  }

  header .navbar ul li ul li ul li ul li {
    background-color: #aaa;
  }

  #menu-bar:checked ~ .navbar {
    display: block;
  }
}

.ntm_prgecap {
  display: flex;
  margin-left: 50px;
  width: 60px;
  text-align: center;
}

.ntm_examsportal {
  display: flex;
  margin-left: 50px;
  width: 60px;
  text-align: center;
}

.ntm_prg {
  margin-top: 20px;
}

.active {
  color: #ffa17f;
}

.ecap_logo {
  width: 100%;
  height: 55px;
}

.examsPortal {
  width: 2cm;
  height: 1.8cm;
  margin: auto;
  margin-top: -6px;
}
/* @media only  screen and (max-width: 600px) { */
  .sai_banner_img {
    width: 100%;
  }
  .ntf_bg_cnt {
    display: flex;
    margin-left: 20px;
    color: rgb(24, 72, 216);
    gap: 10px;
    text-align: center;
    width: 100%;
  }
  .ntf_banner_cnt {
    background-color: #e2bd14;
    display: flex;
   height: 5%;
   /* width: 50%; */
  }

  .ecap_logo {
    width: 100%;
    height: 55px;
  }

  .ntm_prgecap {
    display: flex;
    /* margin-left: 50px; */
    width: 60px;
    text-align: center;
  }
  .ntm_prg {
    margin-top: 10px;
    font-size: 10px;
  }
  .examsPortal {
    width: 2cm;
    height: 1.8cm;
    /* margin: auto; */
    /* margin-top: -6px; */
  }
/* } */
