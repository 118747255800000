.text {
  width: 40%;
  margin-left: 150px;
  margin-top: 30px;
}

.cm {
  margin-right: 150px;
  margin-top: 30px;
}

.frame {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
}
.cm_img {
  width: 100%;
  margin: auto;
  border-radius: 10px;
  height: 600px;
}

.font_st {
  /* font-style: italic; */
  text-align: justify;
}

/* .chairman_msg */

.DM_img {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* margin-bottom: 10px; */
}
@media screen and (max-width: 600px) {
  .DM_img {
    width: 100%;
    max-width: 110%; 
    height: auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: block;
  }

  .frame {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    border-radius: 8px;
    margin: auto;
    padding-right: 2px;
    overflow: hidden;
  }
}
