.Ict_container {
    width: 92%;
    display: flex;
    height: 8cm;
    /* border: 2px solid black; */
    margin: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
    margin-top: 1cm;
    transition: transform 0.3s;
    cursor: pointer;
    
}
.Ict_img {
    width: 100%;
    height: 7cm;
    /* height: 100%; */
    border-radius: 10px 0px 0px 0px;
    object-fit: contain;
}
.Ict_content {
    width: 40%;
    margin: auto;
}
.Ict_carousal {
    width: 50%;
    height: 100%;
}
.Ict_content p {
    font-size: 20px;
}
.ict_btn_container {
    display: flex;
    margin: auto;
    text-align: center;
    margin-top: 0.5cm;
}
.ict_btn_container a{
    margin: auto;
}
.ict_btn_container button {
    margin: auto;
    width: 8cm;
    height: 1cm;
    font-weight: bold;
    border-radius: 10px;
    background-color: blue;
    border: none;
    color: white;
}
.Ict_h1 {
    text-align: center;
    margin-top: 1cm;
}
.ict_btn_container button:hover {
    background-color: black;
    color: aqua;
    transition: transform 0.3s;
}
.Ict_container:hover,.ict_btn_container button:hover {
    transform: scale(1.03);
}

.aicte_heading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    width: fit-content;
    font-size: 20px;
    padding: 10px;
    width: 100%;
    color: #fff;
}

.aicte_heading_container{
   background-image: linear-gradient(to right, #00223e, #ffa17f);
    border:8px;
    
}

.aicte_main_container{
    padding: 30px;
}


.student_faculty_form_main_container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.student_faculty_form_heading{
    color: orange;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 5px solid orangered;
    width: fit-content;
    text-align: center;
    margin: 20px;
    padding-bottom: 10px;
}

.student_faculty_form_sub_container{
    background-image: linear-gradient(to right, #6C5B7B, #C06C84);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin: 20px;
}

.student_faculty_form_container{
    margin: 10px;
}


.student_faculty_form_container input {
    border: 1px solid rgb(43, 169, 169);
    padding: 8px;
    border-radius: 12px;
    background-color: #f4f7f9;
    min-height: 40px;
}

.student_faculty_form_container select{
    border: 1px solid rgb(43, 169, 169);
    padding: 8px;
    min-width:250px;
    border-radius: 12px;
    background-color: #f4f7f9;
    min-height: 40px;
}

.student_faculty_form_container input:focus {
    outline: 2px solid orange;
    border:none; /* Specify the outline style and color */
}

.student_faculty_form_container textarea{
    background-color: #f4f7f9;
    border-radius: 10px;
    border-radius: 12px;
    border: 2px solid rgb(43, 169, 169);
    width: 90%;
    padding: 10px;
}

.student_faculty_form_container label{
    color: #fff;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    flex-grow: 1;
    margin-bottom: 8px;
}

.span_ele{
    font-family: Arial, Helvetica, sans-serif;
    padding-right: 10px;
    font-size: 14px;
    color: #fff;
    letter-spacing: 1px;
}


.aicte_submit_btn{
    background-color: #02334d;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  margin-bottom: 8px;
  color: #fff;
  border-radius: 5px;
  border-width: 0px;
  padding: 5px 10px;
}