.campousMainContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image: -moz-linear-gradient(
        90deg,
        rgb(243, 60, 152) 0%,
        rgb(205, 236, 89) 100%
      );
      background-image: -webkit-linear-gradient(
        90deg,
        rgb(198, 160, 241) 0%,
        rgb(139, 214, 248) 100%
      );
      
}
.Main_container_allumini{
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(
        to right,
        rgb(95, 209, 238) 0%,
        rgb(163, 117, 236) 100%
      );
}
.carousal_alumini{
  height: 60%;
  width:90%;
}
.img_alumini{
    width: 600px;
    height: 350px;
    border:1px solid rgb(212, 243, 247);
    
}
.alumini_style{
  font-size: 40px;
  color: rgb(69, 10, 77);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 40px;
}
.Main_container_allumini_work{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-image: radial-gradient(
        circle farthest-corner at 10% 20%,
        rgb(183, 239, 247) 0%,
        rgb(146, 112, 228) 100%
      );
}