.mainAdv{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(95, 92, 92);
    height: 100%;
}
.AdvertisementImg{
    width: 90%;
    background-size:cover;
}