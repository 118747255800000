.background {
  width: 30%;
  box-shadow: 0px 0px 10px 4px #b77e48;
  border-radius: 5px;
  height: 12cm;
  margin: auto;
  background-color: white;
}
.card_cntr1 {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.card_cntr1 img {
  width: 100%;
  padding: 10px;
}
.card_cntr1 h6 {
  margin-top: 10px;
  text-align: center;
  color: #b77e48;
}
.card_cntr1 img:hover {
  background-color: rgb(6, 187, 204);
}
.color {
  background-color: rgba(255, 248, 220, 0.534);
  padding: 50px;
}
.font {
  text-align: center;
}
.premium-title-text {
  color: #b77e48;
  font-family: "Sacramento", Sans-serif;
  font-size: 45px;
  font-weight: 100;
  font-style: italic;
  letter-spacing: -0.2px;
  background-color: #ffffff00;
}

.h2css {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  color: transparent;
  background: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
  padding: 0px 0px 4px 0px;
  background-image: linear-gradient(220deg, #b77e48 0%, #151515 75%);
}
.h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: transparent;
  background: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
  padding: 0px 0px 4px 0px;
  background-image: linear-gradient(220deg, #b77e48 0%, #151515 75%);
}
.p {
  text-align: center;
  width: 45%;
  margin: auto;
  margin-top: 10px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.row {
  display: flex;
  justify-content: space-around;
  margin: 1cm;
  margin-left: 0px;
  /* padding: 30px;
   width: 100%;
   gap:20px; */
}
.name {
  width: 40%;
  padding: auto;
  height: 400px;
}
.visionp {
  display: flex;
  text-align: left;
  width: 45%;
  padding: auto;
  /* height: 400px; */
}
@media screen and (max-width: 600px) {
  .row {
    display: flex;
    justify-content: space-around;
    /* margin: 1cm;
    margin-left: 0px; */
    /* padding: 30px;
           width: 100%;
           gap:20px; */
  }
}
