.ECE_TableData{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    
    
}
._Top{
    margin-top: 50px;
    font-weight: 800;
    margin-bottom: 20px;
    font-size: 20px;
}
.A{
    font-weight: 900;
    text-align: right;
    margin-right: 150px;
    font-size: 20px;
    margin-bottom: -0.5cm;
}
.Bos_Tabs{
   
    font-weight: bold;
    color: black;
    font-size: 20px;
}

.ECE_TableData table {
    width: 80%;
    border: none;

}._Top
._TableData{
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 25px;
    width: 80%;
    margin-left: 145px;
}

.bottom{
    margin-right: 180px;
    text-align: right;
    margin-top: 0.5cm;
}
._chairperson{
    font-weight: 800;
    font-size:18px;
}
._functions{
    font-weight: bold;

}
._main{
    text-align: left;
    margin-left: 40px;
    margin-bottom: 20px;
}
._head {
    background-color: orangered;
    color: white;
}




