.registartion_form_heading_container {
  display: "flex";
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 20px;
  margin-right: 30px;
}

.registartion_form_heading {
  color: Orange;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 20px;
  margin-right: 30px;
 
}

.registration_form_main_container  {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #000;
  padding: 30px;
  margin: 20px;
  margin-right: 30px;
  border-radius: 10px;
}

.registration_form_container label{
    color: rgb(139, 117, 117);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-right: 30px;
}
.registration_form_input_ele{
  border-radius: 10px;
  border: 1px solid skyblue;
  padding: 5px 5px;
  margin-right: 30px;

}
.registration_form_input_ele:focus {
      outline:1px solid orange;
      border-width: 0px;
}

.registration_form_personalInfo{
  color: rgb(139, 117, 117);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-bottom:5px solid orangered;
  width: fit-content;
  padding: 10px;
  margin-right: 30px;
}


.registration_form_container{
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.registration_form_btn{
  background-color: darkblue;
  color: #fff;
  border-radius: 5px;
  padding: 5px 8px;
  border-width: 0px;
  margin-right: 30px;
}