.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    /* text-transform: capitalize; */
    outline: none;
}

.active {
    color: orangered !important;
    /* font-size: large !important; */
    font-weight: 500 !important;
    background-color: transparent !important;
    text-decoration: none;
}

@media (max-width: 991.98px) {
    .navbar-light .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar-light .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
    height: 75px;
    
}

.navbar-light .navbar-nav .nav-link {
    color: var(--dark);
    font-weight: 500;
}

.navbar-light.sticky-top {
    top: -100px;
    transition: .5s;
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .dropdown-menu.fade-down {
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        transform: rotateX(0deg);
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}
.page_header{
    background-color: #2f3a58;
}
.page_header h1{
    text-align: center;
    padding: 20px;
    color: #eee2e6;
    /* text-transform: capitalize; */
    /* word-spacing: 20px; */
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px !important;
}



/* queries */

@media (max-width: 780px){
    /* .navbar-light .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar-light .navbar-nav {
        border-top: 1px solid #EEEEEE;
    } */
    .hm_header{
        display: none;
    }
    .hm_header_cnt{
        display: none;
    }
    .hm_header_logo_img{
        display: none;
    }
    .image-fluid acc_img logo_img1{
        display: none;
    }
}