/* UG PG Intake */
.table_container {
    width: 65%;
    margin: auto;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 50px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.5s;
}
.intake_img_container {
    width: 95%;
    margin: auto;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    text-align: justify;
    align-items: center;
    justify-content: center;
    margin-top: 1cm;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: background-color 0.9s,color 0.5s; 
    cursor: pointer;

}
.intake_img_container img {
    width: 40%;
    height: 100%;
    border-radius: 20px;
    margin-right: 30px;
}
.intake_img_container p {
    width: 50%;
    margin-left: 40px;
    padding-right: 50px;
    font-style: italic;
    
    
}
.intake_img_container:hover {
    /* background-color: rgba(255, 68, 0, 0.836); */
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    /* color: white;     */
}


/* Admision Procedure */

.addmision_heading {
    text-align: center;
    margin-top: 10px;
    font-style: arial;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
    
}
.procedur_cntr {
    width: 90%;
    margin: auto;
    text-align: justify;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border: 0.1px solid magenta;
    padding: 20px;
    padding-left: 1cm;
    padding-bottom: 1cm;
    margin-bottom: 50px;
}
.procedur_p_text {
    width: 90%;
    margin: auto;
    display: flex;
}
.addmision_heading_sub {
    text-align: center;
    margin-top: 30px;
    font-style: arial;
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
    margin-bottom: 20px;
}
.procedur_three_cntr {
    text-align: left;
    width: 90%;
    margin: auto;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
    padding: 10px;
    border-radius: 10px;
    margin-top: 1cm;
    border: 2px solid orangered;
    cursor: pointer;
}
.procedur_three_cntr_inside {
    width: 90%;
    margin: auto;
    padding: 10px;
    margin-bottom: 10px;

}
.addmision_heading_sub_inside {
    text-align: left;
    font-style: arial;
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
    margin-left: 50px;
    margin-top: 10px;
}
.procedur_three_cntr:hover {
    background-color: rgba(255, 68, 0, 0.836);
    color: white;
    transition: 0.8s;
    border:none;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
}

/* Cntact */
.contact_container {
    text-align: center;
    width: 45%;
    margin: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-top: 20px;
    height: 15cm;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 30px;
}

.contact_main {
    display: flex;
    justify-content: space-around;
}

.contact_head {
    text-align: center;
    margin-top: 20px;
}

.contact_container h4 {
    color: brown;
    margin-bottom: 20px;
    margin-top: 20px;
}

.contact_span {
    font-weight: bold;
    font-size: large;
}

.contact_inp_container {
    text-align: left;
    margin-left: 2cm;
}

.contact_inp_container label {
    font-weight: bold;
    font-size: large;
}

.contact_inp_container input,
.contact_inp_container textarea,
.contact_inp_container button {
    width: 89%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid lightgray;
}
.contact_inp_container input:focus,
.contact_inp_container textarea:focus {
    outline: none;
    border: 1px solid skyblue;
}

.contact_inp_container button {
    background-color: orangered;
    border: none;
    color: white;
    font-size: large;
    font-weight: bold;
    padding: 5px;
}

.contact_inp_container button:hover {
    background-color: blue;
}

.contact_container p {
    font-size: 20px;
}



.Customized_Tables{
    display:flex;
    align-items:"center";
    justify-content: center;
}

@media screen and (max-width: 600px){
    
    .intake_img_container {
        flex-direction: column;
        justify-content: center; 
        text-align: left; 
        /* height: 130%; */
        /* width: 95%; */
      }
      .intake_img_container p {
        width: 100%;
        /* margin-left: 40px; */
        /* padding-right: 50px;
        font-style: italic; */
    }
    .intake_img_container img {
        height: 100%;
        width: 90%;
        
    }
    .container{
        overflow:scroll;
        width: 95%;
    }
  }