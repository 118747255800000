.nav-link {
  color: black;
  font-size: 13px;
}

.nav-link:hover {
  font-weight: 600;
  color: #000000;
}

.course_structure_cnt {
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  background-color: #f5f5f5;
  width: fit-content;
  margin: auto;
  margin-top: 25px;
  border: 1px solid orange;
}

.prg_content_cse{
  font-size: 16px;
  text-align: start;
}


.peo-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.text {
  text-transform: none;
}

.peo {
  /* background-color: #f4f4f4; */
  /* padding: 20px; */
  border-radius: 10px;
  display: flex;
  gap: 20px;
}

.peo h2 {
  color: #333;
  font-size: 15px;
  margin-bottom: 10px;
  white-space: nowrap;
  padding-top: 5px;
}

.peo p {
  color: rgb(76, 10, 98);
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.course_outcomes_cnt {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  padding: 20px;
}

.hov {
  border: 1px solid white;
  height: 11cm;
  width: 32%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 20PX;
  padding: 35px;
  margin: auto;
  cursor: pointer;
  
}
.hov:hover{
  background-color:rgb(6,187,204);
  color: #fff
}

.course_outcomes_cnt li {
  margin: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: rgb(4, 27, 36);
  font-weight: 400;
  text-align: left;
}

.area {
  /* border-radius: 1px; */
  background-color: rgba(0, 191, 255, 0.452);
  padding-top: 30px;
  padding-left: 30px;
  /* border-radius: 10px; */
  width: 45%;
  cursor: pointer ;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


}

.area:hover {
  color: white;
  background-color: rgb(0, 191, 255);
 
}

.accordion_bg {
  background: #8e9eab;
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);
  background: linear-gradient(to right, #eef2f3, #8e9eab);

}

.desc {
  font-size: 14px;
  color: #837a7a;
}
.prg_content_About{
  font-size: 17px;
  margin-left: 2cm;
  line-height: 22px;
  color: rgb(0,94,138);
  font-style: italic;
  /* padding-top: -22px; */
  margin-top: -30px;
  margin-bottom: 2cm;
  text-align: justify;
}
.prg_content_About h1{
  font-size: 35px;
  
}
.img_department{
  width: 15cm;
  height: 10cm;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   padding-left: 2px;
   margin-left: 1cm;
  
}

.mission-container{
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  justify-content: space-between;
}
.mission-sub-heading{
  margin-right: 15px;
  font-size: 18px;
  text-align: left;
}
.mission-sub-subscription{
  text-align: start;
  font-size: 14px;
}
.vision-mission-headings{
  margin-bottom: 20px;
  font-weight: bold;
}