
.policies_table tr td{
padding: 10px;
text-align: left;
}
.policies_table tr th{
 padding: 10px;
 text-align: left;
}
.policies_table{
width: 50%;
margin: auto;
}