.classroom {
    width: 93%;
    margin: auto;
    display: flex;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 2cm;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 7cm;
    border-radius: 10px;
    cursor: pointer;
    transition: transform .5s;
    margin-bottom: 3cm;
}

.classroom:hover {
    transform: scale(1.02);
}

.classroom img {
    width: 40%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}

/*  */

.classroom div {
    width: 50%;
    margin: auto;
}
.align {
    text-align: left;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
}
.h3 {
    text-align: left;
    color:rgb(178,119,240);
}
.hostel {
    margin: auto;
    font-size: 15px;
    text-align: left;
    
    
}
.Transportion {
    margin: auto;
    font-size: 17px;
    text-align: left;
}
.Laboratories {
    margin: auto;
    font-size: 17px;
    text-align: left;
     
}
.Cafeteria {
    margin: auto;
    font-size: 17px;
    text-align: left;
     
}