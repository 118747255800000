.ict_header {
    font-weight: bold;
    text-align: center;
}
.ict_header_2 {
    font-weight: bold;
}
.ict_container {
    width: 70%;
    margin: auto;
    padding: 30px;
    padding-top: 1cm;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-top: 1cm;
    margin-bottom: 1cm;
}
.ict_table {
    margin: auto;
    width: 65%;
    margin-bottom: 1cm;
}
.ict_table th {
    padding: 10px;
    border: 1px solid black;
        
}
.ict_table td {
    padding: 10px;
    border: 1px solid black;
    
}