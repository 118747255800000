.page_header{
    background-color: #2f3a58;
}
.page_header h1{
    text-align: center;
    padding: 15px;
    color: #eee2e6;
    /* text-transform: uppercase; */
    /* word-spacing: 20px; */
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px !important;
}

.companies_img_cnt{
    background: #E6DADA;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #274046, #E6DADA);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #274046, #E6DADA); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        padding: 10px;
    width: 100%;

}