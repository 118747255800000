.vision_cards_cnt {
  display: flex;
  gap: 1.5cm;
}

.vision_cards_head {
  text-align: center;
  margin-top: 20px;
}

.vision_cards_head h3 {
  font-weight: bold;
  font-size: 20px;
}

.vision_cards {
  width: 30%;
  margin: auto;
  height: 14cm;
  border: 1px solid black;
  cursor: pointer;
  /* animation: scrollDown 2s ease infinite alternate; */
  border-radius: 10px;
}

.vision_cards:hover,
.courses_cards:hover {
  background-color: rgb(6, 187, 204);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  font-size: 3px;
  border: none;
  .vision_cards_body {
    color: white;
    padding: 50px;
  }

  .vision_icons {
    color: white;
  }

  .vision_cards_head h3 {
    color: white;
    font-weight: bold;
  }
}

.courses_cards {
  width: 45%;
  margin: auto;
  height: 7cm;
  border: 1px solid black;
  cursor: pointer;
  /* animation: scrollDown 2s ease infinite alternate; */
  border-radius: 10px;
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(40px);
  }
}

.courses_cards .vision_cards_body {
  color: black;
  padding: 40px;
}

.vision_cards_body {
  text-align: start;
  margin-top: -35px;
  padding: 20px;
  color: black;
  font-size: 16px;
}
.mainText {
  padding: 20px;
  border: 2px solid black;
  border-radius: 20px;
}
.headText {
  color: rgb(0, 50, 92);
  text-align: justify;
}
.message {
  display: flex;
  text-align: justify;
}
@media only screen and (max-width: 600px) {
  .vision_cards_cnt {
    display: flex;
    gap: 1.5cm;
  }

  .courses_cards {
    width: 45%;
    margin: auto;
    height: 7cm;
    border: 1px solid black;
    cursor: pointer;
    /* animation: scrollDown 2s ease infinite alternate; */
    border-radius: 10px;
    overflow: scroll;
  }
}
